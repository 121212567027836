import { Injectable } from '@angular/core';
import {
  ApiResponse,
  Organization,
  SystemLogger,
  SystemLoggerPageModel,
} from '@models';
import { BaseService, dayjs } from '@utils';
import { omit } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class SystemLoggerService extends BaseService {
  getLogger(
    filter: SystemLoggerPageModel.filter & {
      organizationId?: Organization['id'];
    }
  ) {
    const paramsFilter: Record<string, unknown> = {
      ...(filter.tags.length > 0 ? { tags: JSON.stringify(filter.tags) } : {}),
      ...Object.entries(omit(filter, ['dateRange', 'tags'])).reduce(
        (acc, [k, v]) => {
          if (Array.isArray(v) && v.length > 0) {
            acc['droper'] = {
              ...(acc['droper'] || {}),
              [k]: JSON.stringify(v),
            };
          } else if (typeof v === 'string') {
            v && (acc[k] = v);
          } else acc[k] = v;
          return acc;
        },
        {} as Record<string, unknown>
      ),
    };
    if (filter['dateRange']?.startDate) {
      paramsFilter['createdDate'] = {
        ...(paramsFilter['createdDate'] || {}),
        gte: new Date(filter['dateRange'].startDate).toISOString(),
      };
    }
    if (filter['dateRange']?.endDate) {
      paramsFilter['createdDate'] = {
        ...(paramsFilter['createdDate'] || {}),
        lte: dayjs(filter['dateRange'].endDate).endOf('D').toISOString(),
      };
      if ((paramsFilter['createdDate'] as Record<string, any>)['gte']) {
        paramsFilter['createdDate'] = {
          between: [
            (<any>paramsFilter['createdDate']).gte,
            (<any>paramsFilter['createdDate']).lte,
          ],
        };
      }
    }

    return this.httpClient.get<ApiResponse<SystemLogger[]>>('/SystemLoggers', {
      params: this.convertToHttpParams(paramsFilter),
    });
  }

  getTags() {
    return this.httpClient.get<{ result: string[] }>('/SystemLoggers/tags');
  }

  deleteLogs(filter: Omit<SystemLoggerPageModel.filter, 'page' | 'limit'>) {
    return this.httpClient.delete<{ result: { message: string } }>(
      'SystemLoggers/deleteByParams',
      {
        params: this.convertToHttpParams({
          ...omit(filter, ['dateRange']),
          ...filter.dateRange,
        }),
      }
    );
  }

  addAPI(data: any) {
    return this.httpClient.post<any>('ApiCalls', data);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getApiCalls(orgId: string, filter?: Record<string, unknown>) {
    // const orgI; // Replace 'your_org_id' with the actual organization ID
    const filter1 = {
      where: {
        organizationId: orgId, // This will only include records with matching organizationId
      },
      order: 'updatedAt DESC', // Sorting by updatedAt in descending order
    };

    const data1 = this.httpClient.get<any>(`ApiCalls`, {
      params: this.convertToHttpParams(filter1),
    });
    return data1;
  }

  hitAPI(data: any) {
    return this.httpClient.post<any>('SystemLoggers/callApi', data);
  }
}
